import React from "react";
import demoGif from "../../../static/videos/RPReplay_Final1671102910.gif";
import { MainAsideStyled } from "./MainAside.Styled";

const MainAside: React.FC = () => {
  return (
    <>
      <MainAsideStyled src={demoGif} alt="demo-gif" />
    </>
  );
};

export default MainAside;
