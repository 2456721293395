import React from "react";
import FooterSection from "../../components/Footer/FooterSection";
import HeaderSection from "../../components/Header/HeaderSection";
import MainSection from "../../components/MainSection/MainSection";
import NavbarSection from "../../components/Navbar/NavbarSectionItem";
import SecondSection from "../../components/SecondSection/SectionSection";
import "./app.scss";
import CookieBanner from "../../components/CookieBanner/CookieBanner";

/**
 * ! Main Instance of the application
 * * ricklarios - 2022/09/07
 */

const App: React.FC = () => {
  return (
    <div id="app">
      <NavbarSection></NavbarSection>
      <div id="main-container">
        <HeaderSection></HeaderSection>
        <div id="sections-container">
          <MainSection></MainSection>
          <SecondSection></SecondSection>
        </div>
        <FooterSection></FooterSection>
        <CookieBanner></CookieBanner>
      </div>
    </div>
  );
};

// * Export the application
export default App;
