import React from "react";
import iphone13 from "../../../static/img/apple-iphone-13.png";
import { MainAsideStyled } from "./MainAside.Styled";

const MainAside: React.FC = () => {
  return (
    <>
      <MainAsideStyled src={iphone13} alt="iphone13-mockup" />
    </>
  );
};

export default MainAside;
