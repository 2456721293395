import styled from "styled-components";
import { Tokens } from "../../static/Tokens";

export const CookieBannerStyled = styled.div`
  color: ${Tokens.Colors.Text.Light.Primary};
  background-color: rgb(72, 72, 71, 0.85);
  font-size: 13px;
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 230px;
  height: 180px;
  border-radius: 20px;
  border: 1px solid #d103a9;
  box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.5);
  padding: 25px;
  text-align: center;
  p {
    text-align: left;
  }
  .cookie-text-second {
    color: #bababa;
  }

  .cookie-btn-accept {
    width: 110px;
    height: 30px;
    border: 1px solid #d103a9;
    border-radius: 5px;
    margin-top: 20px;
    background-color: white;
    font: inherit;
    :hover {
      transform: scale(1.05);
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 408px) {
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
  }
`;
