import styled from "styled-components";
import { Tokens } from "../../static/Tokens";

export const NavbarSectionStyled = styled.nav`
  list-style: none;
  background-color: ${Tokens.Colors.Backgrounds.Secondary};
  color: ${Tokens.Colors.Text.Dark.Primary};
  min-height: 60px;
  font-weight: 400;
  font-size: large;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0px 2px 10px -1px rgba(0, 0, 0, 0.5);

  @media only screen and (max-width: 408px) {
    font-size: small;
    .header-title-text {
      display: none;
    }
  }
  @media only screen and (min-width: 409px) and (max-width: 768px) {
    font-size: medium;
  }
  @media (orientation: landscape) {
  }

  .navbar-section-list {
    overflow: hidden;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding-inline-start: 0px;
    margin: 0px;
  }
  .navbar-title {
    display: flex;
    align-items: center;
    img {
      width: 30px;
      border-radius: 5px;
      margin-right: 10px;
    }
  }
  .navbar-links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
`;
