import React from "react";
// import ContactForm from "./Contact/ContactForm";
import { FooterSectionStyled } from "./FooterSection.Styled";

export const FooterSection: React.FC = () => {
  return (
    <div id="footer-section">
      <FooterSectionStyled>
        {/* <ContactForm></ContactForm> */}
        <a
          href="mailto:dcosfeedback@gmail.com"
          target="_blank"
          rel="noreferrer"
        >
          Any question? Send us an email 📬
        </a>
        <p>COPYRIGHT 2023 © RICARDO LARIOS :: ALL RIGHTS RESERVED</p>
      </FooterSectionStyled>
    </div>
  );
};

export default FooterSection;
