import React from "react";
import { TopArticleStyled } from "./TopArticle.Styled";
import { TopArticleTexts } from "./TopArticle.Texts";

const TopArticle: React.FC = () => {
  return (
    <>
      <TopArticleStyled>
        <h1>{TopArticleTexts.header}</h1>
        <p>{TopArticleTexts.body.p1}</p>
        <p>{TopArticleTexts.body.p2}</p>
      </TopArticleStyled>
    </>
  );
};

export default TopArticle;
