import { useState } from "react";
import React from "react";
import { CookieBannerStyled } from "./CookieBanner.Style";

const CookieBanner: React.FC = () => {
  const [showBanner, setShowBanner] = useState(true);
  let userAnswer: boolean = false;
  const handleClick = () => {
    setShowBanner(false);
    userAnswer = true;
  };
  return (
    <>
      {showBanner && (
        <CookieBannerStyled>
          <p className="cookie-text-first">
            This site doesn't use third party neither its own cookies.
          </p>
          <p className="cookie-text-second">
            No data will be sent to a third party.
          </p>
          <button className="cookie-btn-accept" onClick={handleClick}>
            Accept
          </button>
        </CookieBannerStyled>
      )}
    </>
  );
};

export default CookieBanner;
