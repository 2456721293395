import styled from "styled-components";

export const TopArticleStyled = styled.article`
  // background-color: lightcoral;
  max-width: 40vw;
  min-width: 250px;
  text-align: justify;

  h1 {
    font-size: x-large;
  }

  p {
    font-size: x-large;
    font-weight: 300;
  }
`;
