import React from "react";
import MainAside from "./MainAside/MainAside";
import { MainSectionStyled } from "./MainSection.Styled";
import TopArticle from "./TopArticle/TopArticle";

const MainSection: React.FC = () => {
  return (
    <>
      <MainSectionStyled>
        <TopArticle></TopArticle>
        <MainAside></MainAside>
      </MainSectionStyled>
    </>
  );
};

export default MainSection;
