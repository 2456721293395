import React from "react";
import MainAside from "./MainAside/MainAside";
import { SecondSectionStyled } from "./SecondSection.Styled";
import TopArticle from "./TopArticle/TopArticle";

const SecondSection: React.FC = () => {
  return (
    <>
      <SecondSectionStyled>
        <MainAside></MainAside>
        <TopArticle></TopArticle>
      </SecondSectionStyled>
    </>
  );
};

export default SecondSection;
