import React from "react";
import NavbarItem from "./NavbarItem/NavbarItem";
import { NavbarSectionStyled } from "./NavbarSectionItem.Styled";
import logo from "../../static/img/main-logo.png";

// * Create component
const NavbarSection: React.FC = () => {
  // * View Builder
  return (
    <div id="navbar-section">
      <NavbarSectionStyled>
        <ul className="navbar-section-list">
          <li className="navbar-title">
            <NavbarItem
              className="header-title-text"
              name="home"
              text="Delay Calculator for Audio"
              linkTo="#navbar-section"
              handleClick={() => {
                console.log("Click HOME");
              }}
            >
              <img src={logo} alt="main-logo"></img>
            </NavbarItem>
          </li>
          <li>
            <div className="navbar-links">
              <NavbarItem
                name="contact-us"
                text="Contact us"
                linkTo="#footer-section"
                handleClick={() => {
                  console.log("Click CONTACT US");
                }}
              ></NavbarItem>

              {/* <NavbarItem
                name="terms"
                text="Terms"
                linkTo=""
                handleClick={() => {
                  console.log("Click TERMS");
                }}
              ></NavbarItem>
              <NavbarItem
                name="privacy"
                text="Privacy Policy"
                linkTo=""
                handleClick={() => {
                  console.log("Click PRIVACY");
                }}
              ></NavbarItem> */}
            </div>
          </li>
        </ul>
      </NavbarSectionStyled>
    </div>
  );
};

// * Export module
export default NavbarSection;
