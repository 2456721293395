// * Tokens used for styling

export const Tokens = {
  Colors: {
    Backgrounds: {
      Primary: "#ffffff",
      Secondary: "#f5f5f5",
      Dark: "#808080",
    },
    Text: {
      Dark: {
        Primary: "#000000",
        Hover: "#808080",
      },
      Light: {
        Primary: "#ffffff",
        Secondary: "#f5f5f5",
      },
    },
  },
};
