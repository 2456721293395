import styled from "styled-components";
import { Tokens } from "../../static/Tokens";

export const SecondSectionStyled = styled.section`
  color: ${Tokens.Colors.Text.Dark.Primary};
  background-color: ${Tokens.Colors.Backgrounds.Secondary};

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  padding: 40px;
`;
