import styled from "styled-components";
import { Tokens } from "../../static/Tokens";

export const HeaderSectionStyled = styled.header`
  background-color: #181819;
  max-height: 400px;
  height: 40vw;
  min-height: 175px;
  padding: 10px;
  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    // height: 300px;
    .app-store-log {
    }
  }

  .header-bg {
    position: absolute;
    z-index: 1;
    max-height: 150%;
    margin: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.4;
  }
  .header-content {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    height: 100%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    h1 {
      max-width: 520px;
      margin-top: 3%;
      color: ${Tokens.Colors.Text.Light.Secondary};
      text-align: center;
      vertical-align: middle;
      font-weight: 300;
      font-size: xx-large;
      @media only screen and (max-width: 408px) {
        font-size: large;
      }
      @media only screen and (min-width: 409px) and (max-width: 768px) {
        font-size: x-large;
      }
    }

    a {
      display: flex;

      img {
        width: 60%;
        max-width: 200px;
        margin: auto;
        margin-bottom: 5%;
      }
    }
  }
`;
