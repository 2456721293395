import React from "react";
import { HeaderSectionStyled } from "./HeaderSection.Styled";
import appStoreLogo from "../../static/img/app-store-logo.png";
import dcBg from "../../static/img/dc-bg.png";
import headerSectionTexts from "./HeaderSection.Texts";

const HeaderSection: React.FC = () => {
  return (
    <div id="header-section">
      <HeaderSectionStyled>
        <img src={dcBg} alt="header-bg" className="header-bg" />
        <div className="header-content">
          <h1 className="header-text">{headerSectionTexts.header}</h1>
          <a
            href="https://apps.apple.com/us/app/delay-calculator-for-audio/id1641610845"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={appStoreLogo}
              alt="app-store-logo"
              className="app-store-logo"
            />
          </a>
        </div>
      </HeaderSectionStyled>
    </div>
  );
};

export default HeaderSection;
