import styled from "styled-components";
import { Tokens } from "../../static/Tokens";

export const FooterSectionStyled = styled.footer`
  font-family: inherit;
  color: ${Tokens.Colors.Text.Light.Secondary};
  background-color: ${Tokens.Colors.Backgrounds.Dark};
  padding-top: 10px;
  text-align: center;
  height: 10vh;

  a:link {
    text-decoration: none;
    color: ${Tokens.Colors.Text.Light.Secondary};
  }
  a:visited {
    text-decoration: none;
    color: ${Tokens.Colors.Text.Light.Secondary};
  }
  a:hover {
    color: ${Tokens.Colors.Text.Dark.Primary};
  }
  a {
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    font-size: small;
  }
`;
