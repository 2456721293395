import React from "react";
import PropTypes from "prop-types";
import { NavbarItemStyled } from "./NavbarItem.Styled";

// * Define proptypes
const NavbarItemPropTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  linkTo: PropTypes.string.isRequired,
  className: PropTypes.string,
};

// * Define the type to infer props
type NavbarItemProps = PropTypes.InferProps<typeof NavbarItemPropTypes>;

// * Create component
const NavbarItem: React.FC<NavbarItemProps> = (props) => {
  // * Methods
  const HandleClick = () => props.handleClick();
  const TextBuilder = () => <>{props.text}</>;
  const ImgBuilder = () => <>{props.children}</>;
  const href = () => props.linkTo;
  const className = props.className || "";

  // * View Builder
  return (
    <>
      <NavbarItemStyled onClick={() => HandleClick()} href={href()}>
        <div>{ImgBuilder()}</div>
        <div className={className}>{TextBuilder()}</div>
      </NavbarItemStyled>
    </>
  );
};

// * Export module
export default NavbarItem;
