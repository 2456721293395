import styled from "styled-components";
import { Tokens } from "../../../static/Tokens";

// * Define style of the div
export const NavbarItemStyled = styled.a`
  padding: 5px;
  margin: 10px;
  display: flex;

  :link,
  :visited,
  :active {
    text-decoration: none;
    color: ${Tokens.Colors.Text.Dark.Primary};
  }

  div {
    display: flex;
    align-items: center;
  }

  :hover {
    cursor: pointer;
    color: ${Tokens.Colors.Text.Dark.Hover};
  }
  :disabled {
    box-shadow: none;
    cursor: inherit;
  }
  :active {
    cursor: pointer;
  }
`;
